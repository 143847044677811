export const FACTORY_ADDRESS = '0xA27FF04909f085E98d91473Bce7ABf0577477260'
export const FACTORY_ADDRESS_CAMELARK = '0xfA969454d450dFB86a2dCeFfEc48529F799F4a69'
export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000'

export const POOL_INIT_CODE_HASH = '0xcab8395fa2f76d2201d2d6ced88e76e30e5ae098b9b1f043ab33329084f01d6e'
export const POOL_INIT_CODE_HASH_OPTIMISM = '0xcab8395fa2f76d2201d2d6ced88e76e30e5ae098b9b1f043ab33329084f01d6e'
export const POOL_INIT_CODE_HASH_CAMELARK = '0xcab8395fa2f76d2201d2d6ced88e76e30e5ae098b9b1f043ab33329084f01d6e'
// historical artifact due to small compiler mismatch
export const POOL_INIT_CODE_HASH_OPTIMISM_KOVAN = '0xcab8395fa2f76d2201d2d6ced88e76e30e5ae098b9b1f043ab33329084f01d6e'

/**
 * The default factory enabled fee amounts, denominated in hundredths of bips.
 */
export enum FeeAmount {
  LOW = 500,
  MEDIUM = 3000,
  HIGH = 10000
}

/**
 * The default factory tick spacings by fee amount.
 */
export const TICK_SPACINGS: { [amount in FeeAmount]: number } = {
  [FeeAmount.LOW]: 10,
  [FeeAmount.MEDIUM]: 60,
  [FeeAmount.HIGH]: 200
}
